import React from 'react';
import Header from '../../custom/segments/HeaderPageLogin';

export function Layout({ catalogType, catalogId, children }) {
  return (
    <div>
      <Header catalogType={catalogType} catalogId={catalogId} />
      {children}
    </div>
  );
}
