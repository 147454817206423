import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Grid from '@mui/material/Grid';

// custom components
import { Layout } from '../../components/layout/PageLogin';
import Login from '../../custom/segments/LoginBlocked';
import StyledBox from '../../components/controls/StyledBox';

export const query = graphql`
  query {
    siteSetting: datoCmsSiteSetting {
      title
      company
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
  }
`;

export default function LoginPage({ data, pageContext }) {
  const { siteSetting, site } = data;
  const { catalogType, catalog } = pageContext;

  return (
    <Layout catalogType={catalogType} catalogId={catalog}>
      <HelmetDatoCms seo={siteSetting.seo} favicon={site.favicon} />
      <StyledBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Login />
          </Grid>
        </Grid>
      </StyledBox>
    </Layout>
  );
}
