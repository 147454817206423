import React from 'react';
import { Link } from 'gatsby-theme-material-ui';
import Card from '@mui/material/Card';

// appsolut base components
import StyledBox from '../../components/controls/StyledBox';
import StyledTypography from '../../components/controls/StyledTypography';

function Login() {
  const docLink =
    'https://www.datocms-assets.com/16261/1659966085-reisen_reservierungsbogen_082022.pdf';

  return (
    <Card>
      <StyledBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
        mx={2}
        mt={-3}
        p={3}
        mb={1}
        textAlign="center"
      >
        <StyledTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Mitteilung
        </StyledTypography>
      </StyledBox>
      <StyledBox pt={4} pb={3} px={3}>
        <StyledTypography display="block" variant="button" my={1}>
          Aus technischen Gründen ist die Anmeldung für den Login-Bereich derzeit leider nicht
          möglich. Unsere Urlaubsangebote können sie weiterhin uneingeschränkt einsehen.
        </StyledTypography>
        <StyledTypography display="block" variant="button" my={1}>
          Buchungen von Urlaubsreisen können Sie über unser Büro in Wien vornehmen. Dazu übermitteln
          Sie bitte einen ausgefertigten{' '}
          <StyledTypography
            component={Link}
            to={docLink}
            href={docLink}
            target="_blank"
            rel="noopener noreferrer"
            variant="button"
            color="info"
          >
            Reservierungsbogen
          </StyledTypography>{' '}
          an fair.reisen@post.at. Wir arbeiten an der Lösung des Problemes und ersuchen um ihr
          Verständnis.
        </StyledTypography>
        <StyledBox mt={3} mb={1} textAlign="center">
          <StyledTypography
            component={Link}
            to="/"
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
          >
            ZURÜCK
          </StyledTypography>
        </StyledBox>
      </StyledBox>
    </Card>
  );
}

export default Login;
